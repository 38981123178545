.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 1000;
    overflow: auto;
}
.popup {
    margin: 70px auto;
    padding: 20px;
    border: 2px solid #a879f6;
    background: #0e0922;
    border-radius: 10px;
    width: 300px;
    height: 430px;
    position: relative;
    transition: all 5s ease-in-out;
    z-index: 100;
    font-family: 'Raleway', sans-serif;
}

.popup h2 {
    font-family: 'Equinox', sans-serif;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
}
.popup .close {
    position: absolute;
    top: -13px;
    right: 5px;
    transition: all 200ms;
    font-size: 40px;
    font-weight: bold;
    text-decoration: none;
    color: #a879f6;
}
.popup .close:hover {
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.content ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.content:last-child:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 5px;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

.content:last-child:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 35px;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

.content ul li {
    list-style-type: none;
    margin: 0 auto 10px auto;
}

.icon {
    object-fit: contain;
    width: 35px;
    height: 35px;
}

.button {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding-left: 15px;
    text-align: left;
    width: 285px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #a879f6;
    outline: none;
    background-color: #04020e;
    color: #a879f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.iconWrapper {
    display: flex;
    object-fit: contain;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.iconWrapper img {
    width: 25px;
    height: 25px;
}

.active:hover {
    cursor: pointer;
    background-color: #662074;
}

.inactive {
    /*opacity: .3;*/
    position: relative;
}
.inactive:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10px;
    width: 100%;
    opacity: .5;
    background-color: #fff;
    height: 100%;
}

.inactive:before {
    content: 'Coming soon';
    font-weight: 700;
    position: absolute;
    font-size: 18px;
    top: 25%;
    right: 5px;
    color: #782a80;
    z-index: 10;
}

.info {
    color: #fff;
    display: flex;
    align-items: center;
    margin: auto;
}

.info img {
    width: 20px;
    margin-right: 10px;
    cursor: default;
}

.info a {
    text-decoration: none;
    cursor: pointer;
    margin-left: 5px;
    color: #a879f6;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
    }
}