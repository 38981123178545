.button {
    margin-top: 20px;
    margin-right: 15px;
    color: white;
    background-color: #5B5288;
    border-radius: 10px;
    text-transform: uppercase;
    width: 110px;
    height: 35px;
    text-align: center;
    padding: 0;
    cursor: pointer;
}