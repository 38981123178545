.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    transition: all .3s;
    overflow: auto;
}

.active {
    display: block;
    visibility: visible;
    opacity: 1;
}

.error {
    color: red;
}

.popup {
    font-family: 'Helvetica', sans-serif;
    box-sizing: border-box;
    margin: 200px auto;
    padding: 20px;
    background: #0E0922;
    border-radius: 10px;
    max-width: 435px;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: 2px solid #a879f6;
}

.usdt {
    color: #01bb09;
    margin-left: 10px;
    font-weight: 400;
}
.close {
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 30px;
    cursor: pointer;
    color: #a879f6;
}

.popup h2 {
    font-family: 'Equinox', sans-serif;
    margin-top: 0;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 32px;
}

.balance {
    color: #01bb09;
    font-weight: 400;
}

.selectTitle {
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
}

.arrow {
    height: 8px;
    margin-right: 5px;
    position: absolute;
    right: 0;
}

.selectWrapper {
    width: 430px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px;
    background-color: #000;
    border: 1px solid #a879f6;
    border-radius: 10px;
    color: #fff;
}

.select {
    width: 100%;
    height: 100%;
    outline: none;
    background-color: inherit;
    border: none;
    color: #fff;
    list-style: none;
    visibility: visible;
    opacity: 0;
}


.active ul li {
    display: block;
    visibility: visible;
    opacity: 1;
}

.selectWrapper img {
    height: 40px;
    margin-right: 10px;
}

.alert {
    font-size: 14px;
    color: red;
    margin-bottom: 10px;
    visibility: hidden;
    display: none;
    font-style: italic;
}

.active-alert {
    visibility: visible;
    display: block;
}

.inputName {
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
}

.inputWrapper {
    color: #a879f6;
    display: flex;
    box-sizing: border-box;
    width: 370px;
    height: 35px;
    font-size: 20px;
    background-color: #000;
    border: 1px solid #a879f6;
    border-radius: 10px;
    padding: 25px 10px;
    align-items: center;
    justify-content: space-between;

    &__input {
        color: #fff;
        background-color: inherit;
        outline: none;
        border: none;
        width: 85%;
        font-size: 18px;

    }

    &__span {
        position: absolute;
        font-size: 16px;
        right: 10px;
    }
}



.min {
    color: #a879f6;
    font-size: 16px;
    margin-top: 5px;
}

.gryff {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.gas {
    margin-bottom: 20px;
    color: #fff;
    display: flex;
    align-items: center;
}

.conversion {
    color: #fff;
    margin-bottom: 30px;
}

.total {
    color: #fff;
    display: flex;
    align-items: center;
}

.totalCount {
    color: #01bb09;
    margin: 0 5px 0 20px;
}

.buttonsContainer {
    display: flex;
    bottom: 20px;
    right: 20px;
    align-items: center;
    justify-content: right;
}

.disabled {
    pointer-events: none;
    opacity: .5;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.content {
    display: flex;
    flex-direction: column;

}

.infoIcon {
    position: relative;
}

.infoIcon img {
    height: 16px;
    margin-left: 5px;
}

.tooltip {
    visibility: hidden;
    width: 325px;
    max-width: 325px;
    background-color: #555;
    color: #fff;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 6px;
    position: absolute;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    text-align: center;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 19%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.infoIcon:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.error {
    color: red;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
    }
}