@font-face {
  font-family: "Equinox";
  src:
  url("/src/assets/fonts/Equinox-Regular.woff") format("eot"),
  url("/src/assets/fonts/Equinox-Regular.woff") format("woff"),
  url("/src/assets/fonts/Equinox-Regular.woff") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Raleway";
  src:
    url("/src/assets/fonts/Raleway-Regular.ttf") format("eot"),
    url("/src/assets/fonts/Raleway-Regular.ttf") format("woff"),
    url("/src/assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica";
  src:
    url("/src/assets/fonts/Helvetica.ttf") format("eot"),
    url("/src/assets/fonts/Helvetica.ttf") format("woff"),
    url("/src/assets/fonts/Helvetica.ttf") format("truetype");
  font-weight: 400;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

h1 {
  font-family: Equinox, sans-serif;
  color: #a879f6;
  text-transform: uppercase;
}

h2 {
  font-family: Equinox, sans-serif;
  color: #a879f6;
  text-transform: uppercase;
}

input {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px; /* ширина для вертикального скролла */
  background: linear-gradient(0deg, rgba(18,12,40,1) 0%, rgba(66,50,114,1) 50%, rgba(18,12,40,1) 100%);;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  width: 50%;
  background-color: #a879f6;
  border-radius: 10px;
}

