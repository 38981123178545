.overlay {
    visibility: visible;
    opacity: 1;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 1002;
    overflow: auto;
}

.hide {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.popup {
    /*font-family: 'Raleway', sans-serif;*/
    box-sizing: border-box;
    margin: 200px auto;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #a879f6;
    background-color: #04020e;
    max-width: 550px;
    height: 330px;
    position: relative;
    z-index: 100;
}

.contentWrapper {
    border: 1px solid #a879f6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.popup h2 {
    font-family: 'Equinox', sans-serif;
    margin-top: 0;
    font-weight: 700;
    text-transform: uppercase;
}

.subtitle {
    font-size: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
    padding-left: 50px;
}

.sub1 {
    text-align: center;
    margin-bottom: 5px;
}

.button {
    cursor: pointer;
    width: 100px;
    text-align: center;
    color: #fff;
    height: 40px;
    background-color: #9a83c0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 400;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #b82fd3;
}
.popup .close:hover {
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;

}

.content ul li {
    list-style-type: none;
    margin-bottom: 30px;
}
button {
    padding-left: 20px;
    text-align: left;
    width: 200px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #37305d;
    outline: none;
    background-color: #000;
    color: #952fa0;
}

.active:hover {
    background-color: #662074;
}

.inactive {
    opacity: .3;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
    }
}