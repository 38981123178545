.button {
    font-family: 'Raleway', sans-serif;
    max-width: 100px;
    height: 30px;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    padding: 0;
    font-size: 12px;
    color: #fff;
    background-color: #5b5289;
    cursor: pointer;
}