@import "src/styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: $black-bgc;
}

.header-search {
  background: #1e1839;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  width: 300px;

  &__input {
    border: 0;
    outline: none;
    background: transparent;
    color: $gray-purple;
    flex: 1 1 auto;
    font-size: 16px;

    &::placeholder {
      color: $gray-purple;
    }
  }

  &__icon {
    padding: 5px 10px;
    height: 20px;
  }
}

.header-icons {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    width: 35px;
    height: 35px;
    background: #3b3363;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    img {
      height: 17px;
    }

    &:last-child {
      margin-right: 0;
    }

    &--purple-light {
      background: $gray-purple;
    }
  }

  &__icon {
  }

  &__badge {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray-purple;
    color: $white;
    border-radius: 50%;
    font-weight: bold;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 12px;
  }
}
