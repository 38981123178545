@import "../../styles/variables";

table {
    text-align: left;
    margin: 0;
    color: $white;
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

thead:after {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

thead tr th {
    font-weight: 400;
}

table tr {
    position: relative;
    font-family: 'Helvetica', sans-serif;
}

.item:before {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

table tr td:nth-child(4) {
    text-align: right;
}

table td, table th {
    padding: 25px 10px;
    box-sizing: border-box;
}

tbody td, tbody th {
    background-color: #2c2552;
}

.tableBox {
    background-color: #191431;
    border-radius: 10px;
    margin-bottom: 15px;
}

.mock-item {
    height: 90px;
    box-sizing: border-box;
}

.mock-item:after {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

.iconWrapper {
    position: relative;
    object-fit: contain;
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconWrapper img {
    width: 24px;
    height: 24px;
}

.item {
    &__name {
        display: flex;
        align-items: center;

        &__icon {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }

    &__address {
        max-width: 350px;
        display: flex;
        align-items: center;
    }

    &__balance {
        width: 200px;
        font-size: 16px;
        display: flex;
        align-items: center;

        &__currency {
            font-size: 10px;
            margin-left: 3px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
    }
}

.mockRow {
    height: 70px;
    border: none;
}

.mockRow:before {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}