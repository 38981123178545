.overlaySpinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
}

.loader {
    border: 5px solid #952fa0;
    border-top: 5px solid #662074;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: none;
}

.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
}

.visible {
    display: block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}