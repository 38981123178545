.section {
    margin-top: 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    &__pageTitle {
        font-family: 'Equinox', sans-serif;
        text-transform: uppercase;
        margin: 0;
        font-weight: 700;

        &__button {
            font-family: 'Raleway', sans-serif;
            max-width: 120px;
            height: 50px;
            text-transform: uppercase;
            border-radius: 10px;
            border: none;
            font-size: 16px;
            color: #fff;
            background-color: #5b5289;
            cursor: pointer;
            text-align: center;
            padding: 0;
        }
    }
}



