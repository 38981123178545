@import "../../styles/variables";

table {
    text-align: left;
    margin: 0;
    color: $white;
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

thead:after {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

thead tr th {
    font-weight: 400;
}

table tr {
    position: relative;
    font-family: 'Helvetica', sans-serif;
    display: flex;
    justify-content: space-between;
}

.item:before {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

table tr td:nth-child(4) {
    text-align: right;
}

table td, table th {
    padding: 25px 20px;
    box-sizing: border-box;
}


tbody tr {
    background-color: #2c2552;
}

tbody td, tbody th {
    background-color: inherit;
}

.tableBox {
    background-color: #191431;
    border-radius: 10px;
    margin-bottom: 15px;
}

.mock-item {
    height: 70px;
    box-sizing: border-box;
}

.mock-item:after {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}

.item {
    &__name {
        display: flex;
        align-items: center;
    }

    &__address {
        max-width: 350px;
    }

    &__balance {
        width: 200px;
        font-size: 16px;

        &__currency {
            font-size: 10px;
            margin-left: 3px;
        }
    }
}

.mockRow {
    height: 70px;
    border: none;
    background-color: #191431;
    display: flex;
    justify-content: right;

    &__buttons-wrapper{
        width: 360px;
        display: flex;
        justify-content: space-between;

        &__button {
            font-family: 'Raleway', sans-serif;
            max-width: 145px;
            height: 35px;
            border-radius: 10px;
            border: none;
            font-size: 16px;
            color: #fff;
            background-color: #5b5289;
            cursor: pointer;
            text-align: center;
            padding: 0;
        }
    }
}

.mockRow:before {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    right: 0;
    background: rgb(60,52,98);
    background: linear-gradient(90deg, rgba(60,52,98,1) 0%, rgba(226,136,239,1) 50%, rgba(60,52,98,1) 100%);
}