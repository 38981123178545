@import "src/styles/variables";

.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 340px 1fr; // 340 sidebar
  grid-template-rows: 100px 1fr; // 100 header
}

.sidebar-wrap {
  grid-row: 1 / 3;
}

.routes-wrap {
  padding: 15px;
  background: $black;
}
