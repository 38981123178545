.paginationContainer {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 10px 5px;
    justify-content: right;
}


.paginationItem {
    padding: 0 18px;
    height: 40px;
    max-width: 80px;
    margin: auto 4px;
    color: #fff;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 8px;
    line-height: 1.43;
    font-size: 13px;
    background-color: #2b2549;
    text-align: center;
    font-weight: 700;
}

.prev {
    background-color: #5b5289;
    width: 80px;
    display: flex;
    justify-content: center;
}

.next {
    background-color: #5b5289;
}


.selected {
    background-color: #fff;
    color: #000;
}

.dots:hover {
    background-color: #952fa0;
    cursor: default;
}
.paginationItem:hover {
    background-color: #fff;
    cursor: pointer;
    color: #000;
}

.pagination-container .pagination-item .arrow::before {
    position: relative;
    /* top: 3pt;
     Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.left {
    font-weight: 700;
}
.right {
    font-weight: 700;
}
.paginationItem.disabled {
    pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
