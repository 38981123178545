@import "src/styles/variables";

.sidebar {
  background: $black-bgc;
  height: 100%;
}

.sidebar li {
  margin-bottom: 20px;
}

.sidebar-logo-wrap {
  margin-bottom: 100px;

  img {
    width: 120px;
    height: 120px;
  }
}
