// colors
$black: #000000;
$black-900: #04020e;
$black-bgc: #0e0922;
$purple-700: #5b5288;
$gray-purple: #9a83c0;
$purple-main: #a879f6;
$white: #ffffff;

$forGradient: #0a0623; // sidebar menu item
$forGradient2: #171040;

// font
$fontEquinox: 'Equinox', sans-serif;
$fontRaleway: 'Raleway', sans-serif;
$fontHelvetica: 'Helvetica', sans-serif;

// media query

// other
